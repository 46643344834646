import Newsletter from 'components/UserInfo/MoreData/Newsletter'
import TimesPlusOffers from 'components/UserInfo/MoreData/TimesPlusOffers'
import TimesPlusRedemption from 'components/UserInfo/MoreData/TimesPlusRedemption'
import useFetchUserInfoData from 'components/common/hooks/use-fetch-user-info-data'
import styles from './styles.module.css'

const MoreData: React.FC = () => {
  useFetchUserInfoData()

  return (
    <>
      <div className={styles.offers}>
        <TimesPlusOffers />
        <TimesPlusRedemption />
      </div>
      <Newsletter />
    </>
  )
}

export default MoreData
